var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-grid-row",
    [
      _c(
        "gov-grid-column",
        { attrs: { width: "two-thirds" } },
        [
          _c("gov-heading", { attrs: { size: "l" } }, [
            _vm._v("Terms and Conditions")
          ]),
          _c("gov-body", [
            _vm._v(
              "\n      Review the content for the terms and conditions page on the frontend.\n    "
            )
          ]),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.terms_and_conditions.title,
              label: "Title",
              error: _vm.errors.get("cms.frontend.terms_and_conditions.title"),
              id: "cms.frontend.terms_and_conditions.title"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "title", value: $event })
              }
            }
          }),
          _c("ck-wysiwyg-input", {
            attrs: {
              value: _vm.frontend.terms_and_conditions.content,
              label: "Content",
              error: _vm.errors.get(
                "cms.frontend.terms_and_conditions.content"
              ),
              id: "cms.frontend.terms_and_conditions.content"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "content", value: $event })
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }